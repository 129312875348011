import clsx from "clsx";

export default function Hero({ props }) {
    return (
        <>
            <div className="flex h-screen w-full">
                <div className={clsx(rightSideStyle + ` bg-${props.bgColor} `)}>
                    <div>
                        <div className={titleStyle}>{props.title}</div>
                        <div className="mt-10 max-w-[400px] text-2xl">
                            {props.subheader}
                        </div>
                    </div>
                </div>
                <div className="h-full w-1/2">
                    <img
                        className="object-fit h-full w-full"
                        src={props.headerImg}
                    />
                </div>
            </div>
            <div className={textContainerStyle}>
                <div className="text-2xl font-bold">{props.title}</div>
                <div className={descStyle}>{props.desc}</div>
            </div>
        </>
    );
}

const rightSideStyle = clsx(
    "w-1/2 flex h-full flex-col items-center justify-center",
);
const titleStyle = clsx("max-w-[500px] text-6xl font-bold uppercase");
const textContainerStyle = clsx(
    "my-40 flex w-full flex-col items-center justify-center",
);
const descStyle = clsx("mt-10 max-w-[800px] text-center text-2xl");
