import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter, useLocation } from "react-router-dom";
import clsx from "clsx";
import reportWebVitals from "./reportWebVitals";

import Routes, { routesFlat } from "@routes";
import { ScrollToTop, getArticles } from "@helpers";
import { newsFilterOptions } from "@helpers/constants";
import { Footer, NavDesktop, NavMobile } from "./components";

import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";

ReactGA.initialize("G-KTSEKJ0CT0");

export const CMSContext = createContext(null);
export const NewsContext = createContext(null);

const root = ReactDOM.createRoot(document.getElementById("root"));

const onRedirectCallback = (appState) => {
    window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname,
    );
};

const providerConfig = {
    domain: process.env.REACT_APP_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        ...(process.env.REACT_APP_AUDIENCE
            ? { audience: process.env.REACT_APP_AUDIENCE }
            : null),
    },
};

root.render(
    <Auth0Provider {...providerConfig}>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </Auth0Provider>,
);

function Root() {
    const [state, setState] = useState(undefined);
    const [articles, setArticles] = useState([]);
    const [newsFilter, setNewsFilter] = useState({
        product: newsFilterOptions.product.none,
        type: newsFilterOptions.type.none,
    });

    useEffect(() => {
        getArticles().then((newsfeed) => {
            setArticles(newsfeed);
        });
    }, []);

    const location = useLocation();
    const path = location.pathname;

    const notLuca = path !== routesFlat.luca;
    const notJmrv = path !== routesFlat.jmrv;
    const notReddai = path !== routesFlat.deforest;

    const displayFooter = notLuca && notJmrv && notReddai;

    return (
        <CMSContext.Provider value={{ state, setState }}>
            <div className={siteBodyStyle}>
                <NavDesktop />
                <NavMobile />
                <ScrollToTop />
                <NewsContext.Provider
                    value={{ articles, newsFilter, setNewsFilter }}
                >
                    <Routes />
                </NewsContext.Provider>
                {displayFooter && <Footer />}
            </div>
        </CMSContext.Provider>
    );
}

const siteBodyStyle = clsx("p-0 w-full h-full font-body bg-background");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
