const s3Bucket = `https://ctrees-website.s3.amazonaws.com/staff_photos/`;
const violet = `${s3Bucket}vmoon.jpg`;
const aleena = `${s3Bucket}aashary.jpg`;
const adugna = `${s3Bucket}amullissa.jpg`;
const daniel = `${s3Bucket}dmelling.jpg`;
const fiona = `${s3Bucket}fosborn.jpg`;
const fabien = `${s3Bucket}fwagner.jpg`;
const griffin = `${s3Bucket}gcarter.jpg`;
const ken = `${s3Bucket}kmatsunaga.jpg`;
const labien = `${s3Bucket}lsagang.jpg`;
const matt = `${s3Bucket}mresnick.jpg`;
const naomi = `${s3Bucket}nprovost.jpg`;
const ricardo = `${s3Bucket}rsilva.jpg`;
const shivraj = `${s3Bucket}sgill.jpg`;
const shuang = `${s3Bucket}sli.jpg`;
const vaiman = `${s3Bucket}vshum.jpg`;
const sassanImg = `${s3Bucket}ssaatchi.jpg`;
const susana = `${s3Bucket}stran.jpeg`;
const yan = `${s3Bucket}yyang.jpg`;
const zhihua = `${s3Bucket}zliu.jpg`;
const nich = `${s3Bucket}nkwon.jpg`;
const stephanie = `${s3Bucket}sgeorge.jpg`;
const ray = `${s3Bucket}rsong.jpg`;
const phil = `${s3Bucket}pdecola.jpg`;
const javiera = `${s3Bucket}jcanales.jpeg`;
const stephen = `${s3Bucket}shagen.JPG`;
const cynthia = `${s3Bucket}ccreze.jpeg`;
const dee = `${s3Bucket}dlawrence.jpg`;
const caroline = `${s3Bucket}cdebossart.jpg`;
const william = `${s3Bucket}wboyd.jpg`;
const liz = `${s3Bucket}landerson.jpg`;
const aijing = `${s3Bucket}ali.jpg`;
const lorena = `${s3Bucket}lalves.jpg`;
const xinbo = `${s3Bucket}xlu.jpg`;
const frank = `${s3Bucket}fmwangi.jpg`;

export const teamFunctions = {
    leadership: { display: "leadership", id: "leadership" },
    tech: { display: "technology", id: "tech" },
    science: { display: "science", id: "science" },
    market: { display: "marketing", id: "market" },
    op: { display: "operations", id: "op" },
};

export const altFunctions = {
    board: { display: "board of directors", id: "board" },
    partners: { display: "scientific partners", id: "partners" },
    advisors: { display: "advisors", id: "advisors" },
};

export const leadership = [
    {
        name: "Sassan Saatchi",
        img: sassanImg,
        func: teamFunctions.leadership,
        title: "Co-founder & CEO",
        bio: [
            "Dr. Sassan Saatchi is a senior scientist at the Jet Propulsion Laboratory, California Institute of Technology, and a Adjunct Professor at the Center for Tropical Research, Institute of Environment at the University of California, Los Angeles. He received the Ph.D. degree from George Washington University in 1988 with concentration in electrophysics and applied mathematics. The focus of his Ph.D. dissertation was the modeling of wave propagation in natural media. Dr. Saatchi has been involved in a number of international research studies in modeling global biogenic carbon distribution in tropical and boreal forests, hydrological processes in arid and semi-arid regions, spatial modeling of species distributions, design and scientific applications of several earth science spaceborne sensors.",
            "His present research activities include land cover classification, biomass and soil moisture estimation in boreal forests, land use and land cover change, forest structure and carbon stock in tropical forests, applications of remote sensing in biodiversity and conservation. His research interests also include wave propagation in disordered/random media and EM scattering theory. Sassan Saatchi has been involved in developing and teaching courses in the use of remote sensing for environmental problems.",
        ],
    },
    {
        name: "Stephen Hagen",
        img: stephen,
        func: teamFunctions.leadership,
        title: "COO",
        bio: [
            "Dr. Sassan Saatchi is a senior scientist at the Jet Propulsion Laboratory, California Institute of Technology, and a Adjunct Professor at the Center for Tropical Research, Institute of Environment at the University of California, Los Angeles. He received the Ph.D. degree from George Washington University in 1988 with concentration in electrophysics and applied mathematics. The focus of his Ph.D. dissertation was the modeling of wave propagation in natural media. Dr. Saatchi has been involved in a number of international research studies in modeling global biogenic carbon distribution in tropical and boreal forests, hydrological processes in arid and semi-arid regions, spatial modeling of species distributions, design and scientific applications of several earth science spaceborne sensors.",
            "His present research activities include land cover classification, biomass and soil moisture estimation in boreal forests, land use and land cover change, forest structure and carbon stock in tropical forests, applications of remote sensing in biodiversity and conservation. His research interests also include wave propagation in disordered/random media and EM scattering theory. Sassan Saatchi has been involved in developing and teaching courses in the use of remote sensing for environmental problems.",
        ],
    },
    {
        name: "Aleena Ashary",
        img: aleena,
        func: teamFunctions.leadership,
        title: "Head of Product",
    },
    {
        name: "Daniel Melling",
        img: daniel,
        func: teamFunctions.leadership,
        title: "Head of Marketing",
        bio: [
            "Dr. Sassan Saatchi is a senior scientist at the Jet Propulsion Laboratory, California Institute of Technology, and a Adjunct Professor at the Center for Tropical Research, Institute of Environment at the University of California, Los Angeles. He received the Ph.D. degree from George Washington University in 1988 with concentration in electrophysics and applied mathematics. The focus of his Ph.D. dissertation was the modeling of wave propagation in natural media. Dr. Saatchi has been involved in a number of international research studies in modeling global biogenic carbon distribution in tropical and boreal forests, hydrological processes in arid and semi-arid regions, spatial modeling of species distributions, design and scientific applications of several earth science spaceborne sensors.",
            "His present research activities include land cover classification, biomass and soil moisture estimation in boreal forests, land use and land cover change, forest structure and carbon stock in tropical forests, applications of remote sensing in biodiversity and conservation. His research interests also include wave propagation in disordered/random media and EM scattering theory. Sassan Saatchi has been involved in developing and teaching courses in the use of remote sensing for environmental problems.",
        ],
    },
    {
        name: "Fabien Wagner",
        img: fabien,
        func: teamFunctions.leadership,
        title: "",
    },
    {
        name: "Raymond Song",
        img: ray,
        func: teamFunctions.leadership,
        title: "",
        bio: [
            "Dr. Sassan Saatchi is a senior scientist at the Jet Propulsion Laboratory, California Institute of Technology, and a Adjunct Professor at the Center for Tropical Research, Institute of Environment at the University of California, Los Angeles. He received the Ph.D. degree from George Washington University in 1988 with concentration in electrophysics and applied mathematics. The focus of his Ph.D. dissertation was the modeling of wave propagation in natural media. Dr. Saatchi has been involved in a number of international research studies in modeling global biogenic carbon distribution in tropical and boreal forests, hydrological processes in arid and semi-arid regions, spatial modeling of species distributions, design and scientific applications of several earth science spaceborne sensors.",
            "His present research activities include land cover classification, biomass and soil moisture estimation in boreal forests, land use and land cover change, forest structure and carbon stock in tropical forests, applications of remote sensing in biodiversity and conservation. His research interests also include wave propagation in disordered/random media and EM scattering theory. Sassan Saatchi has been involved in developing and teaching courses in the use of remote sensing for environmental problems.",
        ],
    },
    {
        name: "Phil Decola",
        img: phil,
        func: teamFunctions.leadership,
        title: "Co-founder",
    },
];

export const science = [
    { name: "Griffin Carter", img: griffin, func: teamFunctions.science },
    {
        name: "Ricardo Dalagnol da Silva",
        img: ricardo,
        func: teamFunctions.science,
    },

    { name: "Shuang Li", img: shuang, func: teamFunctions.science },
    { name: "Zhihua Liu", img: zhihua, func: teamFunctions.science },
    { name: "Adugna Mullissa", img: adugna, func: teamFunctions.science },
    { name: "Stephanie George", img: stephanie, func: teamFunctions.science },
    { name: "Ken Matsunaga", img: ken, func: teamFunctions.science },
    { name: "Fiona Osborn", img: fiona, func: teamFunctions.science },
    { name: "Le Bienfaiteur Sagang", img: labien, func: teamFunctions.science },
    { name: "Yan Yang", img: yan, func: teamFunctions.science },
    { name: "Cynthia Creze", img: cynthia, func: teamFunctions.science },
    { name: "Lorena Alves", img: lorena, func: teamFunctions.science },
    { name: "Javiera Canales", img: javiera, func: teamFunctions.science },
];

export const tech = [
    { name: "Naomi Provost ", img: naomi, func: teamFunctions.tech },
    { name: "Violet Moon", img: violet, func: teamFunctions.tech },
    { name: "Liz Anderson", img: liz, func: teamFunctions.tech },
    { name: "Xinbo Lu", img: xinbo, func: teamFunctions.tech },
    { name: "Shivraj Gill", img: shivraj, func: teamFunctions.tech },
    { name: "Matthew Resnick", img: matt, func: teamFunctions.tech },
    { name: "Nicholas Kwon", img: nich, func: teamFunctions.tech },
    { name: "Frank Mwangi", img: frank, func: teamFunctions.tech },
];

export const op = [
    { name: "Susana Tran", img: susana, func: teamFunctions.op },
    { name: "Vaiman Shum", img: vaiman, func: teamFunctions.op },
];

export const market = [
    { name: "Aijing Li", img: aijing, func: teamFunctions.market },
];
export const countries = {
    fr: "France",
    us: "USA",
    br: "Brazil",
    ne: "The Netherlands",
    ga: "Gabon",
    it: "Italy",
    dn: "Denmark",
};

export const partners = {
    fr: [
        {
            name: "Philippe Ciais",
            title: "Co-founder and Research Director, Institut Pierre-Simon Laplace (IPSL)",
            img: "",
        },
        {
            name: "Jean-Pierre Wigneron",
            title: "Co-founder and Head, INRAE Remote Sensing Lab, Bordeaux",
            img: "",
        },
        { name: "Arthur Calvi", title: "Data Analyst" },
        { name: "Ibrahim Fayad", title: "Research Scientist" },
        { name: "Yu Feng", title: "Research Scientist" },
        { name: "Sami Jouaber", title: "Research Scientist" },
        { name: "Agnès Pellissier-Tanon", title: "Research Scientist" },
        { name: "François Ritter", title: "Research Scientist" },
        { name: "Martin Schwartz ", title: "Data Analyst" },
        { name: "Yidi Xu", title: "Research Scientist" },
    ],
    dn: [
        {
            name: "Martin Brandt",
            title: "Co-founder and Professor of Geography, University of Copenhagen",
            img: "",
        },
        {
            name: "Rasmus Fensholt",
            title: "Co-founder and Professor of Geography, University of Copenhagen",
            img: "",
        },
        { name: "Dimitri Gominski", title: "Research Scientist" },
        { name: "Sizhuo Li", title: "Research Scientist" },
        { name: "Siyu Liu", title: "Research Scientist" },
        { name: "Maurice Mugabowindekwe", title: "Research Scientist" },
        { name: "Florian Reiner", title: "Research Scientist" },
    ],
    br: [
        {
            name: "Luiz Aragão",
            title: "Senior Scientist Head of Earth Observation and Geoinformatics Division, National Institute for Space Research (INPE)",
            img: "",
        },
        { name: "Celso H.L. Silva Junior", title: "Research Scientist" },
        { name: "Liana Anderson", title: "Research Scientist" },
    ],
};

export const advisors = {
    us: [
        {
            name: "Susan Cook-Patton",
            title: "Senior Forest Restoration Scientist, The Nature Conservancy",
            local: "USA",
        },
        {
            name: "KC Cushman",
            title: "Postdoctoral Fellow, NASA JPL",
            local: "USA",
        },
        {
            name: "Grant Domke",
            title: "Forest Carbon Scientist, US Forest Service",
            local: "USA",
        },
        {
            name: "Samuel Favrichon",
            title: "Remote Sensing Scientist, Caltech/NASA JPL",
            local: "USA",
        },
        {
            name: "Ron McRoberts",
            title: "Forest Statistician, Raspberry Ridge Analytics",
            local: "USA",
        },
        {
            name: "Herman Shugart",
            title: "Professor Emeritus of Forest Ecology, University of Virginia",
            local: "USA",
        },

        {
            name: "Miles Silman",
            title: "Professor of Biology and Director, Center for Energy, Environment & Sustainability, Wake Forest University",
            local: "USA",
        },

        {
            name: "Compton Tucker",
            title: "Senior Scientist, NASA Goddard Space Flight Center",
            local: "USA",
        },
    ],
    fr: [
        {
            name: "Jerome Chave",
            title: "Laboratoire Évolution & Diversité Biologique, Université Toulouse Paul Sabatier",
            local: "France",
        },
        {
            name: "Alexandre d'Aspremont",
            title: "Professor of Computer Science, Department at Ecole Normale Superieure",
            local: "France",
        },
    ],
    br: [
        {
            name: "Thelma Krug",
            title: "Vice Chair, IPCC; Former Co-chair, IPCC Task Force on National Greenhouse Gas Inventories",
            local: "Brazil",
        },
    ],
    it: [
        {
            name: "Giacomo Grassi",
            title: "Senior Scientific Officer, Joint Research Centre of the European Commission ",
            local: "Italy",
        },
    ],
    ga: [
        {
            name: "Lee White",
            title: "Minister for Forests, Sea, the Environment and Climate Change",
            local: "Gabon",
        },
    ],
    ne: [
        {
            name: "Gert-Jan Nabuurs",
            title: "Professor European Forest Resources, Wageningen University and Research; Lead Scientist European Forest Resources, Wageningen Environmental Research (Alterra)",
            local: "The Netherlands",
        },
    ],
};

export const board = [
    {
        name: "Dee Lawrence",
        title: "Director and co-founder, High Tide Foundation",
        local: "USA",
        img: dee,
    },
    {
        name: "Caroline de Bossart",
        title: "Director, Neglected Climate Opportunities, Grantham Foundation for the Protection of the Environment",
        local: "USA",
        img: caroline,
    },
    {
        name: "William Boyd",
        title: "Professor of Law, UCLA School of Law; Professor, UCLA Institute of the Environment and Sustainability; Project Lead, Governors' Climate and Forests Task Force",
        local: "USA",
        img: william,
    },
];

export const additional = [
    {
        name: "Sassan Saatchi",
        focus: "Forest Carbon Systems",
        title: "Senior Research Scientist, JPL/Caltech; Professor, UCLA Institute of the Environment and Sustainability",
        local: "USA",
    },
    {
        name: "Philippe Ciais",
        focus: "Climate and Carbon",
        title: "Research Director, Institut Pierre-Simon Laplace (IPSL)",
        local: "France",
    },
    {
        name: "Martin Brandt",
        focus: "Data Science and Analytics",
        title: "Professor, Geography, University of Copenhagen",
        local: "Denmark",
    },
    {
        name: "Phil Decola",
        focus: "GHG Inventory",
        title: "Co-chair, WMO Integrated Global GHG Information System",
        local: "USA",
    },
    {
        name: "Rasmus Fensholt",
        focus: "Data Science and Analytics",
        title: "Professor, Geography, University of Copenhagen",
        local: "Denmark ",
    },
    {
        name: "Jean-Pierre Wigneron",
        focus: "Remote Sensing Science",
        title: "Head, INRAE Remote Sensing Lab, Bordeaux",
        local: "France",
    },
];
