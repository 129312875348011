import clsx from 'clsx'
import Lottie from "react-lottie";
import { UnderlinedSectionHeader } from "@components";
import * as lottie from "@assets/lottie";

const beat1Options = {
    ...lottie.defaultOptions,
    animationData: lottie.Beat1,
};

export default function WhyCTrees() {
    return (
        <>
            <UnderlinedSectionHeader>why ctrees?</UnderlinedSectionHeader>
            <div className={parentContainerStyle}>
                <div className={textContainerStyle}>
                    <div className="flex max-w-[400px] flex-col">
                        <div className="mb-10 text-3xl uppercase">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                        </div>
                        <div>
                            Suspendisse semper egestas ipsum, vitae consequat
                            erat pellentesque quis. Suspendisse semper egestas
                            ipsum, vitae consequat erat pellentesque quis.
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <Lottie
                        options={beat1Options}
                        height={400}
                        width={"auto"}
                    />
                </div>
            </div>
        </>
    );
}

const parentContainerStyle = clsx(
    "mb-40 flex w-full items-center justify-center mt-20",
);
const textContainerStyle = clsx("flex w-1/2 items-center justify-center");
