import clsx from "clsx";

export const parent = clsx("flex w-full items-center justify-center mb-40")
export const rowContainer = clsx("flex w-full items-center justify-center p-20")
export const listParent = clsx("flex w-1/2 flex-col items-center justify-center");
export const listContainer = clsx("w-full flex flex-col max-w-[400px]")
export const img = clsx("object-fit h-[300px] w-full max-w-[400px] bg-red")
export const greenBullet = clsx("rounded-full bg-green h-2 w-2 mr-4 mt-2")

export const rowsParent = clsx(
    "flex w-[90%] flex-col items-center justify-center rounded-lg bg-gray",
);