import clsx from "clsx";
import Lottie from "react-lottie";
import { Button, UnderlinedSectionHeader } from "@components";
import * as lottie from "@assets/lottie";
import { routesFlat } from "@routes";

export default function Products() {
    // Note: Can't move to constants because need link route reference.
    const solutions = [
        {
            title: "Global Assessment",
            subheader:
                "Get accurate information on demand with the only global forest carbon and activity data platform.",
            link: routesFlat.globalAssessment,
            animationData: lottie.SolutionEarth,
        },
        {
            title: "Country and State Programs",
            subheader:
                "Get accurate information on demand with the only global forest carbon and activity data platform.",
            link: routesFlat.countryState,
            animationData: lottie.SolutionCali,
        },
        {
            title: "Carbon Markets",
            subheader:
                "Get accurate information on demand with the only global forest carbon and activity data platform.",
            link: routesFlat.carbonMarkets,
            animationData: lottie.SolutionTree,
        },
    ];
    return (
        <>
            <UnderlinedSectionHeader>Solutions</UnderlinedSectionHeader>
            <div className={parentContainerStyle}>
                {solutions.map((solution) => {
                    const lottieOptions = {
                        ...lottie.defaultOptions,
                        animationData: solution.animationData,
                    };

                    return (
                        <div className={solutionContainerStyle}>
                            <div className={titleStyle}>{solution.title}</div>
                            <div className={lottieStyle}>
                                <Lottie
                                    options={lottieOptions}
                                    height={"auto"}
                                    width={300}
                                />
                            </div>

                            <div className={"my-4 text-center"}>
                                {solution.subheader}
                            </div>
                            <Button className="px-10" to={solution.link}>
                                Learn More
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

const parentContainerStyle = clsx("flex w-full justify-around mt-20");
const solutionContainerStyle = clsx("flex flex-col items-center max-w-[320px]");
const titleStyle = clsx("text-2xl uppercase max-w-[220px] text-center mb-4");
const lottieStyle = clsx(
    "bg-black w-full justify-center flex flex-col rounded-full h-[400px]",
);
