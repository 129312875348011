import clsx from "clsx";

export const img = clsx(
    "flex h-full w-full items-center justify-center z-30 absolute",
);
export const mobileTitle = clsx(
    "rounded-t-lg bg-green p-8 text-center text-2xl font-bold capitalize",
);
export const blackOverlay = clsx("absolute z-20 h-full w-full bg-black/40");
export const bgImg = clsx("absolute z-10 h-full object-cover");
