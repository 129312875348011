import clsx from "clsx";

export const parentContainer = clsx(
    "h-solutionHeroMobile md:h-solutionHero items-center flex flex-col md:flex-row w-full bg-[black]",
);
export const textContainer = clsx(
    "flex h-full md:w-1/2 px-8 flex-col items-center justify-center text-center text-white",
);
export const animationContainer = clsx(
    "flex h-full md:w-1/2 w-full items-center",
);
export const subheader = clsx("md:my-10 my-6 max-w-[400px] md:text-lg");
