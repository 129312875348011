import { Link } from "react-router-dom";
import * as styles from "./styles";
import ScaleOnHoverDiv from "../../ScaleOnHoverDiv";

export default function GreenCard({
    img,
    imgAlt,
    title,
    subheader,
    linkDisplayText,
    link,
}) {
    return (
        <ScaleOnHoverDiv className={styles.parentContainer}>
            <Link to={link} className={styles.linkContainer}>
                <div className={styles.imgContainer}>
                    <img
                        src={img}
                        alt={imgAlt}
                        className="min-h-full min-w-full object-cover"
                    />
                </div>
                <div className={styles.textContainer}>
                    <div className="line-clamp-2 text-lg font-bold">
                        {title}
                    </div>
                    <div className={styles.subheader}>{subheader}</div>
                    <div className={styles.linkText}>{linkDisplayText}</div>
                </div>
            </Link>
        </ScaleOnHoverDiv>
    );
}

GreenCard.propTypes = {
    img: String,
    imgAlt: String,
    title: String,
    subheader: String,
    link: String,
    linkDisplayText: String,
};
