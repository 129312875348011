import clsx from "clsx";
import { About } from "@assets/hero";

export default function Hero() {
    return (
        <div className={containerStyle}>
            <div className="flex w-1/2 flex-col">
                <div className={introStyle}>our mission</div>
                <div className={explainStyle}>
                    Track carbon in every living tree of the Earth to accelerate
                    impacts of nature-based climate solutions.
                </div>
            </div>
            <About className="mt-40 w-1/2" />
        </div>
    );
}

const introStyle = clsx("text-bold uppercase tracking-wider text-white mb-10");
const explainStyle = clsx("text-6xl text-white font-bold max-full");
const containerStyle = clsx(
    "mb-40 px-20 flex h-screen w-full items-center justify-between bg-black/50",
);
