import { useState, useEffect } from "react";
import { getProductArticles } from "@helpers";

import { UnderlinedSectionHeader, GreenCard, NoCard } from "@components";

export default function News({ props }) {
    const [productArticles, setProductArticles] = useState([]);

    useEffect(() => {
        getProductArticles(props).then((articles) => {
            setProductArticles(articles);
        });
    }, []);

    console.log(productArticles);

    return (
        <div className="mt-40">
            <UnderlinedSectionHeader>News</UnderlinedSectionHeader>
            <div className="mt-10 w-full">
                {productArticles.length > 0 ? (
                    productArticles.map((article) => {
                        const cardProps = {
                            title: article.title,
                            subheader: article.summary,
                            img: article.image_url,
                            imgAlt: "",
                            link: `/${article.uri}`,
                            linkDisplayText: "Read Articles",
                        };
                        return <GreenCard key={article.id} {...cardProps} />;
                    })
                ) : (
                    <NoCard product={props} />
                )}
            </div>
        </div>
    );
}
