import { UnderlinedSectionHeader, ImgCarousel } from "@components";
import { textContainer } from "../../";
import { teamImages } from './constants'

export default function WhoWeAre() {
    return (
        <>
            <UnderlinedSectionHeader>Who We Are</UnderlinedSectionHeader>
            <ImgCarousel images={teamImages} className="mt-10" />
            <div className={textContainer + " mb-40"}>
                We’re a team of scientific leaders and engineers with over 20
                years of experience in building global carbon monitoring systems
                and solutions that have informed climate change policies.
            </div>
        </>
    );
}
