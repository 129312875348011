import clsx from "clsx";

export default function UnderlinedSectionHeader({ children, className }) {
    return (
        <div className={parentContainerStyle + className}>
            {children}
            <div className={borderStyle}></div>
        </div>
    );
}

const borderStyle = clsx("mt-6 w-[200px] border-b-2 -ml-[6px]");
const parentContainerStyle = clsx(
    "text-7xl font-header tracking-widest max-w-[600px] text-center font-bold uppercase flex flex-col items-center ",
);
