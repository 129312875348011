import clsx from "clsx";
import { Text } from "../components";

export default function ImgText({ textRight = false, title, subheader, img }) {
    const textProps = { title, subheader, textRight };
    return (
        <>
            {/* DESKTOP */}
            <div className="hidden h-[600px] w-full md:flex">
                {!textRight && <Text {...textProps} />}
                <div className={imgContainerStyle}>{img}</div>
                {textRight && <Text {...textProps} />}
            </div>

            {/* MOBILE */}
            <div className={mobileContainerStyle}>
                <div className={mobileTitleStyle}>{title}</div>
                <div className="my-8 h-full">{img}</div>
                <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: subheader }}
                ></div>
            </div>
        </>
    );
}

const imgContainerStyle = clsx("flex h-full w-1/2 items-center justify-center");
const mobileContainerStyle = clsx(
    "flex w-full flex-col items-center px-10 py-10 md:hidden",
);
const mobileTitleStyle = clsx("text-center text-2xl font-bold capitalize");
