import clsx from "clsx";

export const bg = clsx(" h-[300px] w-full flex justify-center");
export const innerTextParentContainer = clsx(
    "w-[90%] h-[300px] rounded-lg bg-gradient-to-r from-lime via-blue to-green to-70% flex p-16 z-10 relative justify-between",
);

export const emailInputContainer = clsx(
    "flex flex-col justify-end relative w-full",
);
export const subscribeContainer = clsx(
    "text-sm flex flex-nowrap items-start mb-10",
);
export const successOrFailMsgContainer = clsx(
    "absolute top-0 text-white font-bold h-[50px] flex flex-col -mt-10 mb-4",
);
export const displayMsg = clsx(
    "w-[0px] border-t-[10px] border-x-[15px] border-t-green border-x-[transparent] ml-4",
);
export const input = clsx(
    "self-start focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-[1px]" +
        " placeholder-slate-400 border-[1px] mb-4 contrast-more:placeholder-slate-500 py-3 px-4 text-[black] tracking-wider bg-[rgba(0,0,0,0)]",
);
