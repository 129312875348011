import * as styles from "./styles";

function Row({ props }) {
    const isEven = props.i % 2 === 0;

    function List() {
        return (
            <div className={styles.listContainer}>
                <div className="text-2xl font-bold">{props.title}</div>
                <div className="mt-8">
                    {props.list.map((item) => (
                        <div className="mb-4 flex">
                            <div className={styles.greenBullet}></div>
                            <div className="w-[400px]">{item}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className={styles.rowContainer}>
            {isEven && (
                <div className={styles.listParent}>
                    <List />
                </div>
            )}
            <div className={styles.listParent}>
                <div className={styles.img}>img</div>
            </div>
            {!isEven && (
                <div className={styles.listParent}>
                    <List />
                </div>
            )}
        </div>
    );
}

export default function Features({ props }) {
    return (
        <div className={styles.parent}>
            <div className={styles.rowsParent}>
                {props.map((feature, i) => (
                    <Row props={{ ...feature, i }} />
                ))}
            </div>
        </div>
    );
}
