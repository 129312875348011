import { Link } from "react-router-dom";
import clsx from "clsx";
import { routesFlat } from "@routes";
import { BlackLogo } from "@assets/icons";
import { Toggle } from "../";

export default function Header(props) {
    return (
        <div className={parentContainer}>
            <Link
                to={routesFlat.landing}
                className={`flex ${props.isOpen ? "fixed" : ""}`}
            >
                <BlackLogo
                    withText={true}
                    className="h-20 w-auto self-center"
                />
            </Link>
            <Toggle {...props} />
        </div>
    );
}

const parentContainer = clsx(
    "absolute top-0 z-40 flex w-full justify-between lg:hidden bg-white/30",
);
