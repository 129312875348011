import clsx from 'clsx'
import { UnderlinedSectionHeader, GreenCard } from "@components";

export default function Data({ cards, subheader }) {
    return (
        <div className={parentContainerStyle}>
            <UnderlinedSectionHeader>Data</UnderlinedSectionHeader>
            <div className="my-10 max-w-[400px] text-center mx-8">{subheader}</div>
            <div
                className={overflowContainerStyle}
                style={{ scrollbarWidth: "none" }}
            >
                <div className="flex h-full w-[3000px]">
                    {cards.map((card) => {
                        return <GreenCard {...card} key={card.title} />;
                    })}
                </div>
            </div>
        </div>
    );
}

const parentContainerStyle = clsx("w-full flex flex-col items-center mt-40");
const overflowContainerStyle = clsx(
    "flex h-[500px] w-screen overflow-y-hidden overflow-x-scroll pl-20",
);

Data.prototypes = {
    cards: Array,
    subheader: String,
};
