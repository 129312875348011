export default function Plus() {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.7207 14.5391L7.7207 0.539063"
                stroke="black"
                stroke-width="2"
            />
            <path
                d="M0.720703 7.53906L14.7207 7.53906"
                stroke="black"
                stroke-width="2"
            />
        </svg>
    );
}
