import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { MetaTagHeader } from "@components";

export default function JMRVMap() {
    const [loading, setLoading] = useState(true);

    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className="w-full mt-[60px]">
                {loading && (
                    <div>
                        <Skeleton className="h-[50px] mb-4" count={1} />
                        <div className="flex">
                            <Skeleton className="h-[100px] ml-10 !w-[300px]" count={5} />
                            <Skeleton className="h-[515px] ml-10 lg:!w-[700px]" />
                        </div>
                    </div>
                )}
                <iframe
                    src=""
                    className="w-full h-full"
                    onLoad={() => setLoading(false)}
                />
            </div>
        </>
    );
}

const metaProps = {
    title: "",
    desc: "",
    img: "",
};