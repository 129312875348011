
import { MetaTagHeader } from "@components";

export default function Reddai() {
  return (
    <>
      <MetaTagHeader {...metaProps} />
      <div>REDD+AI Map</div>
    </>
  );
}

const metaProps = {
  title: "Map individual trees at local to national scales with CTrees",
  desc: "CTrees' tree-level data provides information on individual trees at local to national scales, including in savannas, urban, and altered landscapes. Learn more about our innovative science.",
  img: "",
};