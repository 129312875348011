import clsx from "clsx";

export default function Text({ title, subheader, textRight, green = "" }) {
    const containerStyle = clsx(
        textParentStyle +
            green +
            (textRight ? " rounded-r-lg" : " rounded-l-lg"),
    );

    const innerContainerStyle = clsx(
        textChildStyle + (textRight ? " mr-10" : " ml-10"),
    );
    return (
        <div className={containerStyle}>
            <div className={innerContainerStyle}>
                <div className="mb-4 text-xl font-bold">{title}</div>
                <div dangerouslySetInnerHTML={{ __html: subheader }}></div>
            </div>
        </div>
    );
}

const textParentStyle = clsx(
    "flex items-center justify-center h-full max-w-full md:w-1/2 ",
);
const textChildStyle = clsx("flex max-w-[500px] flex-col justify-center p-10");
