import { useEffect } from "react";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { MenuItem, Header } from "./components";
import { Logout } from "../../Login/components";
import { navRoutes } from "@routes";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";

export default function NavMobile() {
    const { isAuthenticated } = useAuth0();
    const [isOpen, cycleOpen] = useCycle(false, true);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    const headerProps = { isOpen, cycleOpen };
    return (
        <>
            <AnimatePresence>
                {isOpen && (
                    <motion.aside
                        initial={{ height: 0 }}
                        className={asideStyle}
                        animate={animations}
                        exit={{
                            height: 0,
                            transition: { delay: 0.4, duration: 0.3 },
                        }}
                    >
                        <div className={navItemContainer}>
                            <motion.div
                                className={navItemWrapper}
                                initial="closed"
                                animate="open"
                                exit="closed"
                                variants={openVariants}
                            >
                                {navRoutes.map((item) => {
                                    const itemProps = {
                                        ...item,
                                        onClick: cycleOpen,
                                    };
                                    return (
                                        <MenuItem
                                            {...itemProps}
                                            key={item.display}
                                        />
                                    );
                                })}

                                {isAuthenticated && (
                                    <div className="mt-10 flex items-center justify-center">
                                        <Logout />
                                    </div>
                                )}
                            </motion.div>
                        </div>
                    </motion.aside>
                )}
            </AnimatePresence>
            <Header {...headerProps} />
        </>
    );
}

const asideStyle = clsx("z-40 overflow-hidden bg-white/60 backdrop-blur-md");
const navItemContainer = clsx("flex h-screen w-full overflow-scroll");
const navItemWrapper = clsx("mt-20 flex h-full w-full flex-col");

const animations = {
    position: "fixed",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
};

const openVariants = {
    closed: {
        height: 0,
        opacity: 0,
        transition: {
            staggerChildren: 0.2,
            staggerDirection: -1,
        },
    },
    open: {
        height: "auto",
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
            staggerDirection: 1,
            ease: "easeInOut",
        },
    },
};
