// Sample Data
const aboutURI = "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/about/"

export const teamImages = [
    {
        src: `${aboutURI}sassan.jpeg`,
        desc: "CTrees team on UCLA campus at annual retreat in June 2024",
    },
    {
        src: `${aboutURI}team.jpeg`,
        desc: "CTrees team on UCLA campus at annual retreat in June 2024",
    },
    {
        src: `${aboutURI}walking.jpeg`,
        desc: "CTrees team on UCLA campus at annual retreat in June 2024",
    },
    {
        src: `${aboutURI}team.jpeg`,
        desc: "CTrees team on UCLA campus at annual retreat in June 2024",
    },
    {
        src: `${aboutURI}sassan.jpeg`,
        desc: "CTrees team on UCLA campus at annual retreat in June 2024",
    },
];