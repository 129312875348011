import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PlusIcon } from "@assets/icons";
import * as styles from "./styles";

const tagInitial = { height: 0, padding: 0, display: "none" };

function ExpandableTag({ question, answer }) {
    const [isClicked, setIsClicked] = useState(false);
    return (
        <div className={styles.tagContainer}>
            <motion.div
                onClick={() => setIsClicked(!isClicked)}
                className={styles.tagLabel}
            >
                {question}
                <PlusIcon />
            </motion.div>
            <AnimatePresence initial={false}>
                {isClicked && (
                    <motion.div
                        initial={tagInitial}
                        animate={{ height: "auto", display: "flex" }}
                        exit={tagInitial}
                        transition={{
                            type: "spring",
                            duration: 0.4,
                            bounce: 0,
                        }}
                    >
                        <div className="pb-6 pt-10">{answer}</div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default function FAQ({ props }) {
    return (
        <div className={styles.parent}>
            <div className={styles.wrapper}>
                <div className="text-4xl font-bold uppercase">
                    frequently asked questions
                </div>
                <div className={styles.expandContainer}>
                    {props.map((item) => (
                        <ExpandableTag {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
}
