const newsfeedUri = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/newsfeed/summary`;

export function getArticles() {
    return fetch(newsfeedUri, {
        method: "GET",
    })
        .then((resp) => resp.json())
        .then((resp) => resp);
}

export function getProductArticles(product) {
    return fetch(`${newsfeedUri}?tags=${product}&limit=5`)
        .then((resp) => resp.json())
        .then((resp) => resp);
}
