import { UnderlinedSectionHeader, Button } from "@components";
import { routesFlat } from "@routes";
import { textContainer } from "../../";

export default function Careers() {
    return (
        <>
            <UnderlinedSectionHeader>careers</UnderlinedSectionHeader>
            <div className={textContainer}>
                While we're not hiring at the moment, we're always excited to
                connect with talented individuals with strong backgrounds in
                science and technology. Please check back soon for future
                opportunities.
            </div>
            <Button
                to={routesFlat.contact}
                className="mb-20 px-4"
                primary={false}
            >
                contact our team
            </Button>
        </>
    );
}
