// NOTE: Need to import before reassigning to export
// because json files dont have default export values.
import BeatImport from './beats/Beat_1'
import LineImport from './Line'
import CaliImport from './solutions/California'
import EarthImport from './solutions/Earth'
import TreeImport from './solutions/Tree'

// Generic
export const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
export const Line = LineImport

// Homepage
export const Beat1 = BeatImport

export const SolutionCali = CaliImport
export const SolutionEarth = EarthImport
export const SolutionTree = TreeImport