import { IncompleteCircle } from "@assets/beats";

export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const dummyData = [
    {
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        date: "8.1.24",
        link: "/",
    },
    {
        title: "Suspendisse semper egestas ipsum, vitae consequat erat pellentesque quis.",
        date: "11.14.23",
        link: "/",
    },
];

export const hero = {
    title: "Carbon Markets",
    subheader:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper egestas ipsum, vitae consequat erat pellentesque quis.",
};

export const firstBeat = {
    title: "A global data challenge",
    subheader:
        "The global stocktake is a crucial initiative by countries and stakeholders to measure greenhouse gas emissions and assess progress towards Paris Agreement goals. But taking stock of land emissions remains a challenge: Forests and land use are the largest source of uncertainty in the global carbon budget due to inconsistent country inventories and the complex nature of land use activities.",
    img: <IncompleteCircle alt={'hi'} className={"h-4/5 md:h-1/2"}/>,
};
export const secondBeat = {
    title: "Building a unified global picture",
    subheader:
        "By leveraging groundbreaking remote sensing science and AI, CTrees measures carbon stocks and fluxes across all biomes worldwide, including forests, grasslands, mangroves, as well as agricultural and urban lands. The CTrees platform systematically tracks land use activities, mapping deforestation, degradation, and regeneration to offer a comprehensive and precise global assessment of emissions and removals on land.",
    img: <IncompleteCircle alt={'hi'} className={"h-4/5 md:h-1/2"}/>,
    textRight: true
};
export const thirdBeat = {
    title: "Total coverage for total accountability",
    subheader:
        "CTrees’ global greenhouse gas inventory system accounts for emission reductions from smallholder and local initiatives and state and national programs. Our science-based data platform offers an up-to-date assessment of global climate action progress and identifies optimal pathways towards achieving net-zero goals.",
    img: <IncompleteCircle alt={'hi'} className={"h-4/5 md:h-1/2"}/>,
    bg: "",
};

export const dataCards = [
    {
        img: "https://s3-alpha-sig.figma.com/img/d40a/7376/a6247c49379a509ed8597cc1a9b79857?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PdDcsyhqVjacBZoX2b5oTqIeBQrSg-C8zqDXzc9fjZbfwy5-ETCq9HG47HxVGeCvQfwXOWNdmloaevS7hFITFRAhywAzo-L4SoQ1eYvo-xz2JndFizKrYxS~aYvGkzQe0QJKZZeMICD-z8KGfp1PjD4e10RJEYHO-ILJ-s1InxGjBnokVokj7E8eTDWJDS5fThXtWppJbl3apipBN9lYFyyLJ33dh0Fy7RJMzQI399GlO26NdEZ4B-I3oc7xnowd2hVFMWqC9ZMQnioVqkbKEGVhdD61ZTP4MgLZQNBfPJB2oVnbJJfgUBg7mTXJano9TJSJDW5uWgLd~J6HYdj9xg__",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        link: '/jmrv',
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/2fba/8540/4824ec066018847c021bfe9ff3e48177?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SJT5MOIm4KIcQ4fkFPdqDCR4itF~LfJKpSGMVc3jcjWHltSeR9HuAxkVLwAjIiBIaZZE11KC2zT~yrl~h43xZTlxMoBALyAq6JFXnB04Up-Bd5Kfu7R2hrt93IhaQOyno0iNvf8MjL-WQm49~q2OBmDmrLN9YlED30qbhtj1Bp-a17fLZzzw4hl-bzFRb570JgeqZ-aOmVB3l7rPLvm0piwPWwYqJ4CwejfOBADGZffmTG-BSo9IYwfN63X422ZRPH63USIGr0aaBOqc3fxLVmt5~hL4U4PqFeZcfocnlUDFXblvdusbtQC~CaANRg4pKq0x8CV2uKNo8Cto-qvFWg__",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        link: '/land-carbon',
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/d40a/7376/a6247c49379a509ed8597cc1a9b79857?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PdDcsyhqVjacBZoX2b5oTqIeBQrSg-C8zqDXzc9fjZbfwy5-ETCq9HG47HxVGeCvQfwXOWNdmloaevS7hFITFRAhywAzo-L4SoQ1eYvo-xz2JndFizKrYxS~aYvGkzQe0QJKZZeMICD-z8KGfp1PjD4e10RJEYHO-ILJ-s1InxGjBnokVokj7E8eTDWJDS5fThXtWppJbl3apipBN9lYFyyLJ33dh0Fy7RJMzQI399GlO26NdEZ4B-I3oc7xnowd2hVFMWqC9ZMQnioVqkbKEGVhdD61ZTP4MgLZQNBfPJB2oVnbJJfgUBg7mTXJano9TJSJDW5uWgLd~J6HYdj9xg__",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        link: '/jmrv',
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/2fba/8540/4824ec066018847c021bfe9ff3e48177?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SJT5MOIm4KIcQ4fkFPdqDCR4itF~LfJKpSGMVc3jcjWHltSeR9HuAxkVLwAjIiBIaZZE11KC2zT~yrl~h43xZTlxMoBALyAq6JFXnB04Up-Bd5Kfu7R2hrt93IhaQOyno0iNvf8MjL-WQm49~q2OBmDmrLN9YlED30qbhtj1Bp-a17fLZzzw4hl-bzFRb570JgeqZ-aOmVB3l7rPLvm0piwPWwYqJ4CwejfOBADGZffmTG-BSo9IYwfN63X422ZRPH63USIGr0aaBOqc3fxLVmt5~hL4U4PqFeZcfocnlUDFXblvdusbtQC~CaANRg4pKq0x8CV2uKNo8Cto-qvFWg__",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        link: '/land-carbon',
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader = "Lorem ipsum dolor sit amet, consectetur adipiscing elit."