export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const dataCards = [
    {
        img: "https://s3-alpha-sig.figma.com/img/d40a/7376/a6247c49379a509ed8597cc1a9b79857?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PdDcsyhqVjacBZoX2b5oTqIeBQrSg-C8zqDXzc9fjZbfwy5-ETCq9HG47HxVGeCvQfwXOWNdmloaevS7hFITFRAhywAzo-L4SoQ1eYvo-xz2JndFizKrYxS~aYvGkzQe0QJKZZeMICD-z8KGfp1PjD4e10RJEYHO-ILJ-s1InxGjBnokVokj7E8eTDWJDS5fThXtWppJbl3apipBN9lYFyyLJ33dh0Fy7RJMzQI399GlO26NdEZ4B-I3oc7xnowd2hVFMWqC9ZMQnioVqkbKEGVhdD61ZTP4MgLZQNBfPJB2oVnbJJfgUBg7mTXJano9TJSJDW5uWgLd~J6HYdj9xg__",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/2fba/8540/4824ec066018847c021bfe9ff3e48177?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SJT5MOIm4KIcQ4fkFPdqDCR4itF~LfJKpSGMVc3jcjWHltSeR9HuAxkVLwAjIiBIaZZE11KC2zT~yrl~h43xZTlxMoBALyAq6JFXnB04Up-Bd5Kfu7R2hrt93IhaQOyno0iNvf8MjL-WQm49~q2OBmDmrLN9YlED30qbhtj1Bp-a17fLZzzw4hl-bzFRb570JgeqZ-aOmVB3l7rPLvm0piwPWwYqJ4CwejfOBADGZffmTG-BSo9IYwfN63X422ZRPH63USIGr0aaBOqc3fxLVmt5~hL4U4PqFeZcfocnlUDFXblvdusbtQC~CaANRg4pKq0x8CV2uKNo8Cto-qvFWg__",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/d40a/7376/a6247c49379a509ed8597cc1a9b79857?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PdDcsyhqVjacBZoX2b5oTqIeBQrSg-C8zqDXzc9fjZbfwy5-ETCq9HG47HxVGeCvQfwXOWNdmloaevS7hFITFRAhywAzo-L4SoQ1eYvo-xz2JndFizKrYxS~aYvGkzQe0QJKZZeMICD-z8KGfp1PjD4e10RJEYHO-ILJ-s1InxGjBnokVokj7E8eTDWJDS5fThXtWppJbl3apipBN9lYFyyLJ33dh0Fy7RJMzQI399GlO26NdEZ4B-I3oc7xnowd2hVFMWqC9ZMQnioVqkbKEGVhdD61ZTP4MgLZQNBfPJB2oVnbJJfgUBg7mTXJano9TJSJDW5uWgLd~J6HYdj9xg__",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://s3-alpha-sig.figma.com/img/2fba/8540/4824ec066018847c021bfe9ff3e48177?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SJT5MOIm4KIcQ4fkFPdqDCR4itF~LfJKpSGMVc3jcjWHltSeR9HuAxkVLwAjIiBIaZZE11KC2zT~yrl~h43xZTlxMoBALyAq6JFXnB04Up-Bd5Kfu7R2hrt93IhaQOyno0iNvf8MjL-WQm49~q2OBmDmrLN9YlED30qbhtj1Bp-a17fLZzzw4hl-bzFRb570JgeqZ-aOmVB3l7rPLvm0piwPWwYqJ4CwejfOBADGZffmTG-BSo9IYwfN63X422ZRPH63USIGr0aaBOqc3fxLVmt5~hL4U4PqFeZcfocnlUDFXblvdusbtQC~CaANRg4pKq0x8CV2uKNo8Cto-qvFWg__",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader: "New data finds emissions from deforestation and degradation increased in 2023.",
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader = ''