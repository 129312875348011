import { motion } from "framer-motion";
import clsx from "clsx";

export default function Tabs(props) {
    return (
        <div className={parent}>
            <div className={tabContainer}>
                {props.tabs?.map((tab) => {
                    const selected = props.selectedTab === tab.id
                    const selectedTabStyle = selected ? "" : "text-black/50"
                    return (
                        <div
                            key={tab.id}
                            className={selectedTabStyle + " cursor-pointer text-2xl"}
                            onClick={() => props.setTab(tab)}
                        >
                            {tab.display}
                            {selected && (
                                <motion.div
                                    className={underline}
                                    layoutId={props.id}
                                    initial={false}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 50,
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={border} />
        </div>
    );
}

const parent = clsx("flex w-[90%] flex-col mt-10");
const border = clsx("-mt-[2px] h-[2px] w-full bg-gray");
const underline = clsx("mt-2 h-[2px] w-full bg-green");
const tabContainer = clsx(
    "flex w-full z-10 flex w-full justify-around text-lg uppercase",
);
